import { RecipeVariant, cva, cx } from "#style/css";

const spinnerStyle = cva({
  base: {
    _after: {
      display: "block",
      borderRadius: "50%",
      border: "2px solid",
      borderColor: "#000 transparent #000 transparent",
      animation: "spin 1.2s linear infinite",
    },
  },
  variants: {
    size: {
      sm: {
        width: "6",
        height: "6",
        _after: {
          width: "6",
          height: "6",
        },
      },
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

type SpinnerSize = RecipeVariant<typeof spinnerStyle>["size"];

export type SpinnerProps = {
  size?: SpinnerSize;
};

export function Spinner(props: SpinnerProps) {
  return <div class={cx(spinnerStyle({ size: props.size }), "pseudo-after")} />;
}
