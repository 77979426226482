import { ComponentProps, JSX, Show, splitProps } from "solid-js";
import { Dynamic } from "solid-js/web";

import { RecipeVariantProps, cva, cx } from "#style/css";
import { styled } from "#style/jsx";

import { Typography } from "./Typography";
import { Link } from "./Link";
import { Spinner } from "./Spinner";

const buttonStyle = cva({
  base: {
    position: "relative",
    display: "inline-flex",
    flexDir: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "4",
    px: "6",
    borderRadius: "sm",
    border: "2px solid",
    flexBasis: "0",
    transition: "box-shadow 150ms linear, transform 150ms linear",
    whiteSpace: "nowrap",
    _active: {
      transform: "scale(0.95)",
    },
    cursor: "pointer",
  },
  variants: {
    color: {
      yellow: {
        borderColor: "yellow",
      },
      darkGray: {
        borderColor: "darkGray",
      },
      linen: {
        borderColor: "linen",
      },
    },
    status: {
      primary: {},
      secondary: {
        backgroundColor: "transparent",
      },
    },
    ctx: {
      dark: {
        _hover: {
          boxShadow: "-8px 8px 0px 0px var(--colors-white)",
        },
      },
      light: {
        _hover: {
          boxShadow: "-8px 8px 0px 0px var(--colors-dark-gray)",
        },
      },
    },
    size: {
      md: {
        py: "2",
      },
      lg: {
        py: "3",
      },
    },
    isLoading: {
      true: {
        cursor: "not-allowed",
      },
    },
  },
  compoundVariants: [
    {
      color: "yellow",
      status: "primary",
      css: {
        backgroundColor: "yellow",
        color: "black",
      },
    },
    {
      color: "darkGray",
      status: "primary",
      css: {
        backgroundColor: "darkGray",
        color: "white",
      },
    },
    {
      color: "linen",
      status: "primary",
      css: {
        backgroundColor: "linen",
        color: "darkGray",
      },
    },

    {
      color: "yellow",
      status: "secondary",
      css: {
        color: "yellow",
      },
    },
    {
      color: "darkGray",
      status: "secondary",
      css: {
        color: "darkGray",
      },
    },
    {
      color: "linen",
      status: "secondary",
      css: {
        color: "linen",
      },
    },
  ],
  defaultVariants: {
    size: "md",
    color: "yellow",
    status: "primary",
    ctx: "light",
    isLoading: false,
  },
});

type ButtonTagAttributes = {
  span: JSX.HTMLAttributes<HTMLSpanElement>;
  button: JSX.ButtonHTMLAttributes<HTMLButtonElement>;
  a: ComponentProps<typeof Link>;
};
type ButtonTag = keyof ButtonTagAttributes;
type ButtonVariantTypes = Exclude<
  RecipeVariantProps<typeof buttonStyle>,
  undefined
>;

type ButtonProps<T extends ButtonTag> = {
  tag: T;
  leftIcon?: () => JSX.Element;
  size?: ButtonVariantTypes["size"];
  color?: ButtonVariantTypes["color"];
  status?: ButtonVariantTypes["status"];
  ctx?: ButtonVariantTypes["ctx"];
  isLoading?: boolean;
} & ButtonTagAttributes[T];

export function Button<T extends ButtonTag>(props: ButtonProps<T>) {
  const [local, otherProps] = splitProps(props, [
    "class",
    "tag",
    "children",
    "leftIcon",
    "size",
    "color",
    "status",
    "ctx",
    "isLoading",
  ]) as [ButtonProps<T>, ButtonProps<T>]; // Workaround because TS does stuggle here

  return (
    <Dynamic
      component={local.tag === "a" ? Link : (local.tag as string)}
      class={cx(
        buttonStyle({
          size: local.size,
          status: local.status,
          color: local.color,
          ctx: local.ctx,
          isLoading: local.isLoading,
        }),
        local.class,
      )}
      {...otherProps}
    >
      <Show when={local.leftIcon}>
        <styled.span visibility={local.isLoading ? "hidden" : "visible"}>
          {local.leftIcon?.()}
        </styled.span>
      </Show>
      <styled.span visibility={local.isLoading ? "hidden" : "visible"}>
        <Typography tag="span" textStyle="xsmallImportant">
          {local.children}
        </Typography>
      </styled.span>

      <Show when={local.isLoading}>
        <styled.div
          aria-hidden
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="10"
        >
          <Spinner />
        </styled.div>
      </Show>
    </Dynamic>
  );
}
